<template>
  <el-dialog
      :title="title"
      :visible="isVisible"
      @close="close"
      center
      v-loading="loading"
  >
    <p>
      <font-awesome-icon icon="info-circle" />
      {{ $t('system.report_will_contain_selected_columns') }}
    </p>
    <el-form class="mt-4">
      <el-form-item
          :label="$t('system.group_by')"
          :size="fieldsSize"
      >
        <el-select
            clearable
            filterable
            v-model="form.groupBy"
        >
          <el-option
              :label="$t('system.project')"
              value="project"
          />
        </el-select>
      </el-form-item>
      <el-form-item
          :label="$t('system.report_type')"
          :size="fieldsSize"
      >
        <el-radio
            :label="false"
            v-model="form.timeEntries"
        >
          {{ $t('system.issues_report') }}
        </el-radio>
        <el-radio
            :label="true"
            v-model="form.timeEntries"
        >
          {{ $t('system.time_entries_report') }}
        </el-radio>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <div class="d-flex justify-content-between">
        <div>
          <el-button
              :size="fieldsSize"
              @click="generate('pdf')"
              type="primary"
          >PDF</el-button>
          <el-button
              :size="fieldsSize"
              @click="generate('doc')"
              type="primary"
          >DOC</el-button>
        </div>
        <el-button
            :size="fieldsSize"
            @click="close"
        >
          {{ $t('system.cancel') }}
        </el-button>
      </div>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'GenerateReportModal',
  props: {
    title: {
      type: String,
    },
    isVisible: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      fieldsSize: 'small',
      form: {
        groupBy: null,
        timeEntries: false,
      },
    };
  },
  methods: {
    generate(type) {
      this.$emit('generate', type, this.form);
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>

</style>
